import {cloneJson} from "../Common/CloneCommon";
import moment from "moment";
import axios from 'axios';
import {dispatch} from "../App";
import {SnackbarOpen} from "../Action/SnackbarAction";
import {AuthenticationLogout} from "../Action/AuthenticationAction";

const routeApi = {
    api_default: '/api/',

    // Authentication
    api_authentication_login: '/api/authentication/login',
    api_authentication_logout: '/api/authentication/logout',

    // Buy
    api_buy_order_purveyor_list: '/api/buy/order-purveyor/list',
    api_buy_order_purveyor_check: '/api/buy/order-purveyor/check',
    api_buy_order_purveyor_delivery: '/api/buy/order-purveyor/delivery',
    api_buy_order_purveyor_deposit_default: '/api/buy/order-purveyor/deposit-default',
    api_buy_order_purveyor_add: '/api/buy/order-purveyor/add',
    api_buy_order_purveyor_get: '/api/buy/order-purveyor/{id}',
    api_buy_order_purveyor_edit: '/api/buy/order-purveyor/{id}',
    api_buy_order_purveyor_delete: '/api/buy/order-purveyor/{id}',
    api_buy_order_purveyor_download: '/api/buy/order-purveyor/{id}/download',
    api_buy_order_purveyor_bc_upload: '/api/buy/order-purveyor/{id}/bc-upload',
    api_buy_order_purveyor_bc_download: '/api/buy/order-purveyor/{id}/bc-download',

    api_buy_product_manage_weight_list: '/api/buy/product/manage-weight/list',
    api_buy_product_manage_weight_edit: '/api/buy/product/manage-weight/edit/{id}',
    api_buy_product_list: '/api/buy/product/list',
    api_buy_product_add: '/api/buy/product/add',
    api_buy_product_get: '/api/buy/product/{id}',
    api_buy_product_edit: '/api/buy/product/{id}',
    api_buy_product_sylius: '/api/buy/product/{id}/sylius',
    api_buy_product_purveyor_add: '/api/buy/product/{productId}/purveyor/add',
    api_buy_product_purveyor_list: '/api/buy/product/{productId}/purveyor/list',
    api_buy_product_purveyor_delete: '/api/buy/product/{productId}/purveyor/{id}',
    api_buy_product_purveyor_change_moq: '/api/buy/product/{productId}/purveyor/{id}/change-moq',

    api_buy_product_by_product_list: '/api/buy/product/{productId}/by-product/list',
    api_buy_product_by_product_add: '/api/buy/product/{productId}/by-product/add',
    api_buy_product_by_product_get: '/api/buy/product/{productId}/by-product/{id}',
    api_buy_product_by_product_edit: '/api/buy/product/{productId}/by-product/{id}',
    api_buy_product_by_product_delete: '/api/buy/product/{productId}/by-product/{id}',
    api_buy_product_by_product_purveyor_list: '/api/buy/product/{productId}/by-product/{byProductId}/purveyor/list',
    api_buy_product_by_product_stock_list: '/api/buy/product/{productId}/by-product/{byProductId}/stock/list',
    api_buy_product_by_product_purveyor_change: '/api/buy/product/{productId}/by-product/{byProductId}/purveyor/{purveyorId}/change',
    api_buy_product_by_product_by_product_list: '/api/buy/product/{productId}/by-product/{byProductId}/by-product/list',
    api_buy_product_by_product_by_product_add: '/api/buy/product/{productId}/by-product/{byProductId}/by-product/add',
    api_buy_product_by_product_by_product_delete: '/api/buy/product/{productId}/by-product/{byProductId}/by-product/{id}',
    api_buy_product_by_product_by_product_change_amount: '/api/buy/product/{productId}/by-product/{byProductId}/by-product/{id}/change-amount',

    api_buy_purveyor_list: '/api/buy/purveyor/list',
    api_buy_purveyor_add: '/api/buy/purveyor/add',
    api_buy_purveyor_get: '/api/buy/purveyor/{id}',
    api_buy_purveyor_edit: '/api/buy/purveyor/{id}',
    api_buy_purveyor_add_product: '/api/buy/purveyor/{id}/product/{productId}',
    api_buy_purveyor_by_product_change_price: '/api/buy/purveyor/{purveyorId}/by-product/{byProductId}/change-price',
    api_buy_purveyor_by_product_list: '/api/buy/purveyor/{purveyorId}/by-product/list',
    api_buy_purveyor_by_purveyor_order_list: '/api/buy/purveyor/{id}/by-purveyor/order/list',
    api_buy_purveyor_contact_list: '/api/buy/purveyor/{purveyorId}/contact/list',
    api_buy_purveyor_contact_add: '/api/buy/purveyor/{purveyorId}/contact/add',
    api_buy_purveyor_contact_edit: '/api/buy/purveyor/{purveyorId}/contact/{id}',

    // Inventory Management
    api_inventory_management_deposit_list: '/api/inventory-management/deposit/list',
    api_inventory_management_deposit_add: '/api/inventory-management/deposit/add',
    api_inventory_management_deposit_get: '/api/inventory-management/deposit/{id}',
    api_inventory_management_deposit_edit: '/api/inventory-management/deposit/{id}',
    api_inventory_management_inventory_deposit_by_product_list: '/api/inventory-management/inventory/deposit/{deposit}/by-product/list',
    api_inventory_management_inventory_deposit_inventory_list: '/api/inventory-management/inventory/deposit/{deposit}/inventory/list',
    api_inventory_management_inventory_deposit_inventory_info: '/api/inventory-management/inventory/deposit/{deposit}/inventory/info',
    api_inventory_management_inventory_deposit_inventory_add: '/api/inventory-management/inventory/deposit/{deposit}/inventory/add',
    api_inventory_management_inventory_deposit_inventory_validated: '/api/inventory-management/inventory/deposit/{deposit}/inventory/validated',
    api_inventory_management_inventory_deposit_inventory_download: '/api/inventory-management/inventory/deposit/{deposit}/inventory/{inventory}/download',
    api_inventory_management_deposit_address_list: '/api/inventory-management/deposit/{depositId}/address/list',
    api_inventory_management_deposit_address_add: '/api/inventory-management/deposit/{depositId}/address/add',
    api_inventory_management_deposit_address_edit: '/api/inventory-management/deposit/{depositId}/address/{id}',
    api_inventory_management_deposit_address_delete: '/api/inventory-management/deposit/{depositId}/address/{id}',
    api_inventory_management_deposit_by_product_list: '/api/inventory-management/deposit/{depositId}/by-product/list',
    api_inventory_management_deposit_by_product_add_stock: '/api/inventory-management/deposit/{depositId}/by-product/add-stock',
    api_inventory_management_deposit_by_product_move_stock: '/api/inventory-management/deposit/{depositId}/by-product/{byProductId}/move-stock',

    api_inventory_management_movement_list: '/api/inventory-management/movement/list',

    api_inventory_management_receipt_order_purveyor_list: '/api/inventory-management/receipt-order-purveyor/list',
    api_inventory_management_receipt_order_purveyor_get: '/api/inventory-management/receipt-order-purveyor/{id}',
    api_inventory_management_receipt_order_purveyor_edit: '/api/inventory-management/receipt-order-purveyor/{id}',
    api_inventory_management_receipt_purveyor_bl_upload: '/api/inventory-management/receipt-order-purveyor/{id}/bl-upload',
    api_inventory_management_receipt_purveyor_bl_download: '/api/inventory-management/receipt-order-purveyor/{id}/bl-download',
    api_inventory_management_receipt_purveyor_bc_download: '/api/inventory-management/receipt-order-purveyor/{id}/bc-download',

    api_inventory_management_preparer_manager_preparer_list: '/api/inventory-management/preparer-manager/preparer/list',
    api_inventory_management_preparer_manager_preparer_edit: '/api/inventory-management/preparer-manager/preparer/{id}',
    api_inventory_management_preparer_manager_preparer_activity_inactive: '/api/inventory-management/preparer-manager/preparer/activity-inactive/{id}',
    api_inventory_management_preparer_manager_order_list: '/api/inventory-management/preparer-manager/order/list',

    api_inventory_management_preparer_order_list: '/api/inventory-management/preparer/order/list',
    api_inventory_management_preparer_order_edit: '/api/inventory-management/preparer/order/{uuid}',

    api_inventory_management_valorisation_stock_list: '/api/inventory-management/valorisation-stock/list',
    api_inventory_management_valorisation_stock_list_csv: '/api/inventory-management/valorisation-stock/list?csv=true',

    api_inventory_management_return_order_list: '/api/inventory-management/return-order/list',
    api_inventory_management_return_order_list_by_shop: '/api/inventory-management/return-order/list/by-shop/{shopId}',
    api_inventory_management_return_order_by_product_list: '/api/inventory-management/return-order/{uuid}/by-product/list',
    api_inventory_management_return_order_add: '/api/inventory-management/return-order/{uuid}/add',
    api_inventory_management_return_order_add_from_shop: '/api/inventory-management/return-order/{uuid}/add/from/shop',
    api_inventory_management_return_order_litige_receive: '/api/inventory-management/return-order/litige/{uuid}/receive',

    // Billing
    api_billing_invoice_list: '/api/billing/invoice/list',
    api_billing_invoice_download: '/api/billing/invoice/{uuid}/download',
    api_billing_credit_list: '/api/billing/credit/list',
    api_billing_refund_list: '/api/billing/refund/list',
    api_billing_credit_download: '/api/billing/credit/{uuid}/download',
    api_billing_credit_repaid: '/api/billing/credit/{uuid}/repaid',


    // Administrator
    api_administrator_user_list: '/api/administrator/user/list',
    api_administrator_user_add: '/api/administrator/user/add',
    api_administrator_user_get: '/api/administrator/user/{id}',
    api_administrator_user_archive: '/api/administrator/user/archive/{id}',
    api_administrator_user_edit: '/api/administrator/user/{id}',
    api_administrator_user_role_list: '/api/administrator/user/{userId}/role/list',
    api_administrator_user_role_change: '/api/administrator/user/{userId}/role/change',

    api_administrator_log_sylius_list: '/api/administrator/log-sylius/list',

    api_administrator_log_carrier_list: '/api/administrator/log-carrier/list',
    api_administrator_log_carrier_generate_print_label: '/api/administrator/log-carrier/{id}/generate-print-label',
    api_administrator_log_carrier_label_printer: '/api/administrator/log-carrier/{id}/label-printer/{labelPrinter}',

    api_administrator_log_printer_list: '/api/administrator/log-printer/list',
    api_administrator_log_printer_printing: '/api/administrator/log-printer/{id}/printing',

    api_administrator_request_async_list: '/api/administrator/request-async/list',

    // Statistic
    api_statistic_preparations: '/api/statistic/preparations',
    api_statistic_preparations_download: '/api/statistic/preparations/download',

    api_statistic_sale_payment: '/api/statistic/sale/payment',
    api_statistic_trades: '/api/statistic/trades',
    api_statistic_trades_download: '/api/statistic/trades/download',
    api_statistic_trades_retail: '/api/statistic/trades/retail',
    api_statistic_trades_reporting: '/api/statistic/trades/reporting',

    api_statistic_marks: '/api/statistic/marks',
    api_statistic_marks_download: '/api/statistic/marks/download',

    api_statistic_buy_ca: '/api/statistic/buy/ca',
    api_statistic_buy_portfolio_details: '/api/statistic/buy/portfolio-details',

    api_statistic_sale_monthly: '/api/statistic/sale/monthly',
    // Sale
    api_sale_client_list: '/api/sale/client/list',
    api_sale_client: '/api/sale/client/{uuid}',
    api_sale_client_info: '/api/sale/client/info/{id}',
    api_sale_update_client: '/api/sale/client/update',
    api_sale_promo_list: '/api/sale/promo/list',
    api_sale_promo: '/api/sale/promo/edit/{id}',
    api_sale_promo_add: '/api/sale/promo/add',
    api_sale_update_promo: '/api/sale/promo/update/{id}',
    api_sale_client_download: '/api/sale/client/download/clients',

    api_sale_order_client_list: '/api/sale/order-client/list',
    api_sale_order_client: '/api/sale/order-client/{uuid}',
    api_sale_order_client_litige: '/api/sale/order-client/{uuid}/litige',
    api_sale_order_client_cancel: '/api/sale/order-client/{uuid}/cancel',
    api_sale_order_invoice_download: '/api/sale/order-client/{uuid}/invoice-download',
    api_sale_order_credit_download: '/api/sale/order-client/{uuid}/credit-download',

    api_sale_client_comment_list: '/api/sale/client/{uuid}/comment/list',
    api_sale_client_comment_add: '/api/sale/client/{uuid}/comment/add',

    //Client Service
    api_client_service_order_client_litige_list: '/api/client-service/order-client-litige/list',
    api_client_service_order_client_litige: '/api/client-service/order-client-litige/{uuid}',
    api_client_service_order_client_litige_state: '/api/client-service/order-client-litige/{uuid}/state',
    api_client_service_order_client_litige_reshipment: '/api/client-service/order-client-litige/{uuid}/reshipment',
    api_client_service_order_client_litige_refund: '/api/client-service/order-client-litige/{uuid}/refund',

    // Shop Management
    api_shop_management_shop_region_list: '/api/shop-management/shop-region/list',
    api_shop_management_shop_region_add: '/api/shop-management/shop-region/add',
    api_shop_management_shop_region_edit: '/api/shop-management/shop-region/{id}',

    api_shop_management_shop_list: '/api/shop-management/shop/list',
    api_shop_management_shop_get: '/api/shop-management/shop/{id}',
    api_shop_management_shop_add: '/api/shop-management/shop/add',
    api_shop_management_shop_edit: '/api/shop-management/shop/{id}',
    api_shop_management_shop_list_reappro: '/api/shop-management/shop/list/reappro',
    api_shop_management_shop_reappro_products_edit: '/api/shop-management/shop/reappro/products/edit/{id}',
    api_shop_management_shop_reappro_edit: '/api/shop-management/shop/reappro/edit/{id}',
    api_shop_management_shop_reappro_convert: '/api/shop-management/shop/reappro/convert-to-order/{id}',
    api_shop_management_shop_reappro_validate_transfer: '/api/shop-management/shop/reappro/validate-transfer/{id}',
    api_shop_management_shop_reappro_refuse_transfer: '/api/shop-management/shop/reappro/refuse-transfer/{id}',
    api_shop_management_closing_list: '/api/shop-management/shop/closing/list',
    api_shop_management_shop_closing_get: '/api/shop-management/shop/closing/{id}',
    api_shop_management_shop_closing_get_recap_day: '/api/shop-management/shop/closing/resume/{closeId}',

    api_shop_management_shop_print_label: '/api/shop-management/shop/products/{shopId}/print-label',

    api_shop_management_shop_by_product_list: '/api/shop-management/shop/{shopId}/by-product/list',
    api_shop_management_shop_by_product_list_admin: '/api/shop-management/shop/{shopId}/by-product/list-admin',
    api_shop_management_shop_by_product_add: '/api/shop-management/shop/{shopId}/by-product/add',
    api_shop_management_shop_by_product_delete: '/api/shop-management/shop/{shopId}/by-product/{id}',
    api_shop_management_shop_by_product_csv_upload: '/api/shop-management/shop/{shopId}/by-product/upload',

    //B2B : Client Business
    api_b2b_client_business_list: '/api/b2b/client-business/list',
    api_b2b_client_business_add: '/api/b2b/client-business/add',
    api_b2b_client_business_edit: '/api/b2b/client-business/{id}',
    api_b2b_client_business_edit_identifiant_connexion: '/api/b2b/client-business/identifiant-connexion/{id}',
    api_b2b_client_business_edit_price_list: '/api/b2b/client-business/price-list/{id}',
    api_b2b_client_business_get: '/api/b2b/client-business/{id}',
    api_b2b_client_business_upload: '/api/b2b/client-business/upload',
    api_b2b_client_business_first_contact: '/api/b2b/client-business/{id}/first-contact',
    api_b2b_client_business_archive: '/api/b2b/client-business/{id}/archive',

    //B2B : Client Business Contact
    api_b2b_client_business_contact_list: '/api/b2b/client-business-contact/list/{id}',
    api_b2b_client_business_contact_add: '/api/b2b/client-business-contact/add',
    api_b2b_client_business_contact_edit: '/api/b2b/client-business-contact/edit',
    api_b2b_client_business_contact_delete: '/api/b2b/client-business-contact/delete/{id}',
    api_b2b_client_business_contact_get: '/api/b2b/client-business-contact/{id}',

    //B2B : Price
    api_b2b_client_business_price_list: '/api/b2b/client-business-price/list',
    api_b2b_client_business_price_list_add: '/api/b2b/client-business-price/add',
    api_b2b_client_business_price_list_get: '/api/b2b/client-business-price/{id}',
    api_b2b_client_business_price_list_products_get: '/api/b2b/client-business-price/products/{id}',
    api_b2b_client_business_price_list_products_already_priced_get: '/api/b2b/client-business-price/products-already-priced/{id}',
    api_b2b_client_business_price_list_edit: '/api/b2b/client-business-price/edit/{id}',
    api_b2b_client_business_price_list_compute_price_list_item: '/api/b2b/client-business-price/compute/price-list-item',

    //B2B : Order
    api_b2b_client_business_order_list: '/api/b2b/client-business-order/list/{id}',
    api_b2b_client_business_order_add: '/api/b2b/client-business-order/add',
    api_b2b_client_business_order_send_proposal_pdf: '/api/b2b/client-business-order/send/proposal/{id}',

    //B2B : Product Available to sell
    api_b2b_client_business_global_by_product_available: '/api/b2b/client-business-global-by-product-available/list',
    api_b2b_client_business_global_by_product_available_edit: '/api/b2b/client-business-global-by-product-available/edit/{id}',

    //Shop
    api_shop_sales: '/api/shop/{shopId}/sales/list',
    api_shop_sales_check_opening: '/api/shop/sales/check-open/{shopId}',
    api_shop_sales_check_closing: '/api/shop/sales/check-closing/{shopId}',
    api_shop_sales_client_list: '/api/shop/sales/client/list/{shopId}',
    api_shop_order_client_list: '/api/shop/sales/order-client/list/{shopId}',
    api_shop_order_client_list_today: '/api/shop/sales/order-client/list/today/{shopId}',
    api_shop_sales_open: '/api/shop/sales/open-shop/{id}',
    api_shop_sales_close: '/api/shop/sales/close-shop/{id}',
    api_shop_sales_select_product: '/api/shop/sales/products/select/{shopId}/{id}',
    api_shop_sales_create_order_shop: '/api/shop/sales/order/create/{shopId}/{id}',
    api_shop_sales_add_product: '/api/shop/sales/products/add/{shopId}/{productId}/{orderClientId}/{free}',
    api_shop_sales_add_payment: '/api/shop/sales/add-payment/{orderClientId}',
    api_shop_sales_get_payment: '/api/shop/sales/get-payment/{orderClientId}',
    api_shop_seller_get_stock_by_product_by_shop: '/api/shop/seller/stock/shops/{byProductId}',
    api_order_client_close: '/api/order-client/{orderClientId}/close',
    api_shop_sales_shop_info: '/api/shop/sales/shop/info/{id}',
    api_shop_sales_client_info: '/api/shop/sales/client/info/{id}',
    api_shop_sales_create_client: '/api/shop/sales/client/create/{shopId}',
    api_shop_sales_get_byproduct_by_ean: '/api/shop/sales/products/get-by-ean/{ean}',
    api_shop_sales_get_byproduct_offered: '/api/shop/sales/products/offered',
    api_shop_sales_remove_product: '/api/shop/sales/order-client/product/delete/{id}',
    api_shop_sales_remove_pac: '/api/shop/sales/order-client/pac/delete/{orderId}',
    api_shop_sales_validate_sign: '/api/shop/sales/validate/sign',
    api_shop_sales_pending_sign: '/api/shop/sales/pending/sign/{shopId}',
    api_shop_sales_check_promo: '/api/shop/sales/promo/check/{orderClientId}',
    api_shop_sales_reset_payment: '/api/shop/sales/reset-payment/{orderClientId}',
    api_shop_sales_delete_order_client: '/api/shop/sales/order-client/delete/{orderClientId}/{shopId}',
    api_shop_sales_get_recap_day: '/api/shop/sales/resume/{shopId}',
    api_shop_seller_alerte_responsable_inventory: '/api/shop/seller/alerte-shop-inventory/{id}/{deposit}/{inventory}',
    api_shop_seller_etiquette_download: '/api/shop/seller/etiquette/{byProductId}/download',

    //Shop reappro
    api_shop_reappro_list_by_products: '/api/shop/reappro/byProducts/list/{shopId}/{depositId}',
    api_shop_reappro_reception_by_products: '/api/shop/reappro/byProducts/reception/{reapproId}',
    api_shop_reappro_get_last: '/api/shop/reappro/get/{shopId}',
    api_shop_reappro_list_by_products_save: '/api/shop/reappro/byProducts/list/{shopId}/save/{validate}/{depositId}',
    api_shop_reappro_reception_by_products_save: '/api/shop/reappro/byProducts/reception/{reapproId}/save',
    api_shop_reappro_reception_by_products_presave: '/api/shop/reappro/byProducts/reception/{reapproId}/presave',
    api_shop_reappro_reception_by_products_precheck: '/api/shop/reappro/byProducts/reception/{reapproId}/precheck',
    api_shop_reappro_list_reappro_by_shop: '/api/shop/reappro/list/byShop/{shopId}',

    // Setting
    api_setting_family_sylius: '/api/setting/family/sylius',
    api_setting_family_list: '/api/setting/family/list',
    api_setting_family_add: '/api/setting/family/add',
    api_setting_family_edit: '/api/setting/family/{id}',

    api_setting_subfamily_sylius: '/api/setting/subfamily/sylius',
    api_setting_subfamily_list: '/api/setting/subfamily/list',
    api_setting_subfamily_add: '/api/setting/subfamily/add',
    api_setting_subfamily_edit: '/api/setting/subfamily/{id}',

    api_setting_carrier_list: '/api/setting/carrier/list',
    api_setting_carrier_add: '/api/setting/carrier/add',
    api_setting_carrier_edit: '/api/setting/carrier/{id}',

    api_setting_country_list: '/api/setting/country/list',
    api_setting_country_add: '/api/setting/country/add',
    api_setting_country_edit: '/api/setting/country/{id}',

    api_setting_tva_country_list: '/api/setting/tva-country/list',
    api_setting_tva_country_add: '/api/setting/tva-country/add',
    api_setting_tva_country_edit: '/api/setting/tva-country/{id}',

    api_setting_printer_list: '/api/setting/printer/list',
    api_setting_printer_add: '/api/setting/printer/add',
    api_setting_printer_edit: '/api/setting/printer/{id}',

    api_setting_label_printer_list: '/api/setting/label-printer/list',
    api_setting_label_printer_add: '/api/setting/label-printer/add',
    api_setting_label_printer_edit: '/api/setting/label-printer/{id}',

    api_setting_mark_sylius: '/api/setting/mark/sylius',
    api_setting_mark_list: '/api/setting/mark/list',
    api_setting_mark_add: '/api/setting/mark/add',
    api_setting_mark_edit: '/api/setting/mark/{id}',

    api_setting_product_type_list: '/api/setting/product-type/list',
    api_setting_product_type_add: '/api/setting/product-type/add',
    api_setting_product_type_edit: '/api/setting/product-type/{id}',

    api_setting_galenic_sylius: '/api/setting/galenic/sylius',
    api_setting_galenic_list: '/api/setting/galenic/list',
    api_setting_galenic_add: '/api/setting/galenic/add',
    api_setting_galenic_edit: '/api/setting/galenic/{id}',

    api_setting_sector_sylius: '/api/setting/sector/sylius',
    api_setting_sector_list: '/api/setting/sector/list',
    api_setting_sector_add: '/api/setting/sector/add',
    api_setting_sector_edit: '/api/setting/sector/{id}',

    api_setting_pack_size_sylius: '/api/setting/pack-size/sylius',
    api_setting_pack_size_list: '/api/setting/pack-size/list',
    api_setting_pack_size_add: '/api/setting/pack-size/add',
    api_setting_pack_size_edit: '/api/setting/pack-size/{id}',

    api_setting_flavor_sylius: '/api/setting/flavor/sylius',
    api_setting_flavor_list: '/api/setting/flavor/list',
    api_setting_flavor_add: '/api/setting/flavor/add',
    api_setting_flavor_edit: '/api/setting/flavor/{id}',

    api_setting_purveyor_type_list: '/api/setting/purveyor-type/list',
    api_setting_purveyor_type_add: '/api/setting/purveyor-type/add',
    api_setting_purveyor_type_edit: '/api/setting/purveyor-type/{id}',

    api_setting_payment_terms_list: '/api/setting/payment-terms/list',
    api_setting_payment_terms_add: '/api/setting/payment-terms/add',
    api_setting_payment_terms_edit: '/api/setting/payment-terms/{id}',

    api_setting_life_cycle_list: '/api/setting/life-cycle/list',
    api_setting_life_cycle_add: '/api/setting/life-cycle/add',
    api_setting_life_cycle_edit: '/api/setting/life-cycle/{id}',

    api_setting_tva_list: '/api/setting/tva/list',
    api_setting_tva_add: '/api/setting/tva/add',
    api_setting_tva_edit: '/api/setting/tva/{id}',

    api_setting_channel_list: '/api/setting/channel/list',
    api_setting_channel_add: '/api/setting/channel/add',
    api_setting_channel_edit: '/api/setting/channel/{id}',

    api_setting_channel_client_list: '/api/setting/channel-client/list',
    api_setting_channel_client_add: '/api/setting/channel-client/add',
    api_setting_channel_client_edit: '/api/setting/channel-client/{id}',

    api_setting_user_function_list: '/api/setting/user-function/list',
    api_setting_user_function_add: '/api/setting/user-function/add',
    api_setting_user_function_edit: '/api/setting/user-function/{id}',

    api_setting_shop_movement_list: '/api/setting/shop-movement/list',
    api_setting_shop_movement_add: '/api/setting/shop-movement/add',
    api_setting_shop_movement_edit: '/api/setting/shop-movement/{id}',

    api_setting_order_client_litige_type_list: '/api/setting/order-client-litige-type/list',
    api_setting_order_client_litige_type_add: '/api/setting/order-client-litige-type/add',
    api_setting_order_client_litige_type_edit: '/api/setting/order-client-litige-type/{id}',

    api_setting_order_client_forwarding_list: '/api/setting/order-client-forwarding/list',
    api_setting_order_client_forwarding_add: '/api/setting/order-client-forwarding/add',
    api_setting_order_client_forwarding_edit: '/api/setting/order-client-forwarding/{id}',

    api_setting_order_client_refund_list: '/api/setting/order-client-refund/list',
    api_setting_order_client_refund_add: '/api/setting/order-client-refund/add',
    api_setting_order_client_refund_edit: '/api/setting/order-client-refund/{id}',

    // Select
    api_select_order_purveyor_state: '/api/select/order-purveyor-state',
    api_select_order_client_state: '/api/select/order-client-state',
    api_select_family: '/api/select/family',
    api_select_purveyor: '/api/select/purveyor',
    api_select_subfamily: '/api/select/subfamily',
    api_select_printer: '/api/select/printer',
    api_select_label_printer: '/api/select/label-printer',
    api_select_mark: '/api/select/mark',
    api_select_region: '/api/select/region',
    api_select_product_type: '/api/select/product-type',
    api_select_galenic: '/api/select/galenic',
    api_select_life_cycle: '/api/select/life-cycle',
    api_select_sector: '/api/select/sector',
    api_select_unit_measure: '/api/select/unit-measure',
    api_select_purveyor_type: '/api/select/purveyor-type',
    api_select_payment_terms: '/api/select/payment-terms',
    api_select_flavor: '/api/select/flavor',
    api_select_client_business_contact_role: '/api/select/client-business-contact-role',
    api_select_pack_size: '/api/select/pack-size',
    api_select_tva: '/api/select/tva',
    api_select_deposit: '/api/select/deposit',
    api_select_movement_action: '/api/select/movement-action',
    api_select_channel: '/api/select/channel',
    api_select_channel_stats: '/api/select/channel-stats',
    api_select_channel_client: '/api/select/channel-client',
    api_select_carrier: '/api/select/carrier',
    api_select_carrier_dpd: '/api/select/carrier/dpd',
    api_select_deposit_address: '/api/select/deposit-address',
    api_select_country: '/api/select/country',
    api_select_tva_country: '/api/select/tva-country',
    api_select_order_client_litige_state: '/api/select/order-client-litige-state',
    api_select_order_client_litige_type: '/api/select/order-client-litige-type',
    api_select_order_client_forwarding: '/api/select/order-client-forwarding',
    api_select_order_client_reception: '/api/select/order-client-reception',
    api_select_order_client_refund: '/api/select/order-client-refund',
    api_select_shop_region: '/api/select/shop-region',
    api_select_user_function: '/api/select/user-function',
    api_select_shop_movement: '/api/select/shop-movement',
    api_select_user: '/api/select/user',
    api_select_shop: '/api/select/shop',
    api_select_user_preparer: '/api/select/user-preparer',
    api_select_by_product: '/api/select/by-product',
    api_select_price_list: '/api/select/price-list',
    api_select_product: '/api/select/product',
    api_select_payment_method: '/api/select/payment-method',

    // AutoComplete
    api_auto_complete_purveyor: '/api/auto-complete/purveyor',
    api_auto_complete_by_product: '/api/auto-complete/by-product',
    api_auto_complete_product: '/api/auto-complete/product',
    api_auto_complete_deposit: '/api/auto-complete/deposit',
    api_auto_complete_order_client: '/api/auto-complete/order-client',
};

const cacheConfig = {
    /*api_select_galenic: 14400,
    api_select_life_cycle: 14400,
    api_select_product_type: 14400,
    api_select_sector: 14400,
    api_select_unit_measure: 14400,
    api_select_mark: 14400,
    api_select_flavor: 14400,
    api_select_pack_size: 14400,
    api_select_purveyor_type: 14400,
    api_select_payment_terms: 14400,
    api_select_tva: 14400,
    api_select_deposit: 14400,
    api_select_movement_action: 14400,
    api_select_channel: 14400,
    api_select_channel_client: 14400,
    api_select_carrier: 14400,
    api_select_printer: 14400,
    api_select_family: 14400,
    api_select_country: 14400,*/
};

function ApiHandler() {
    let cache = {};

    this.host = function () {
        return window.location.protocol + '//' + window.location.host;
    };
    this.route = function (options) {
        let route = routeApi[options.route];

        if (!route) {
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: 'Route introuvable (ApiHandler.js)',
                    variant: 'error',
                })
            );
            return;
        }

        if (options.data) {
            route += '?';
            for (let index in options.data) {
                let dataGet = options.data[index] === null ? '' : options.data[index];
                dataGet = dataGet === undefined ? '' : dataGet;

                if (Array.isArray(dataGet)) {
                    for (let index1 in dataGet) {
                        route += index + "[]=" + dataGet[index1] + "&";
                    }
                } else {
                    if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
                }
            }
            route = route.slice(0, -1);
        }

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        return route;
    };
    this.get = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({status: 404});
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: 'Route introuvable (ApiHandler.js)',
                    variant: 'error',
                })
            );
            return;
        }

        if (options.data) {
            route += '?';
            for (let index in options.data) {
                let dataGet = options.data[index] === null ? '' : options.data[index];
                dataGet = dataGet === undefined ? '' : dataGet;

                if (Array.isArray(dataGet)) {
                    for (let index1 in dataGet) {
                        route += index + "[]=" + dataGet[index1] + "&";
                    }
                } else {
                    if (dataGet || dataGet === 0 || dataGet === false) route += index + "=" + dataGet + "&";
                }
            }
            route = route.slice(0, -1);
        }

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        if (cache[route] && cache[route].age.isAfter(moment())) {
            let data = cloneJson(cache[route].data);
            callback(data);
        } else {
            axios.get(route, {withCredentials: true})
                .then(function (response) {
                    if (cacheConfig[options.route]) {
                        if (options.route in cacheConfig) {
                            cache[route] = {
                                data: cloneJson(response.data),
                                age: moment().add(cacheConfig[options.route], 's'),
                            };
                            callback(cloneJson(response.data));
                        }
                    } else {
                        callback(response.data);
                    }
                })
                .catch(err => {
                    console.log(options.route);
                    console.log(route);
                    console.log(err);
                    if (err.response.status === 401) {
                        dispatch(AuthenticationLogout())
                    } else {
                        if (err.response.data.status === 403 && err.response.data.error.message) {
                            dispatch(
                                SnackbarOpen({
                                    text: err.response.data.error.message,
                                    variant: 'error',
                                })
                            );
                        }
                        callback(err.response.data);
                    }
                });
        }
    };
    this.post = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({status: 404});
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: 'Route introuvable (ApiHandler.js)',
                    variant: 'error',
                })
            );
            return;
        }

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        let data;
        if (options.file) {
            data = new FormData();
            for (let property in options.file) {
                data.append(property, options.file[property]);
            }
            if (options.data) {
                data.append('_json', JSON.stringify(options.data));
            }
        } else {
            data = JSON.stringify(options.data);
        }

        axios.post(route, data, {withCredentials: true})
            .then(function (response) {
                callback(response.data);
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.delete = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        axios.delete(route, {withCredentials: true})
            .then(function (response) {
                callback(response.data);
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });

    };
    this.postDownload = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        let data;
        if (options.file) {
            data = new FormData();
            for (let property in options.file) {
                data.append(property, options.file[property]);
            }
            if (options.data) {
                data.append('_json', JSON.stringify(options.data));
            }
        } else {
            data = JSON.stringify(options.data);
        }

        axios.post(route, data, {withCredentials: true, responseType: 'blob'})
            .then(response => new File([response.data], options.filename))
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                callback({status: 200});
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.getDownload = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({status: 404});
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: '404',
                    variant: 'error',
                })
            );
            return;
        }

        if (!options.data) {
            options.data = {download: 1};
        } else {
            options.data.download = 1;
        }

        route += '?';
        for (let index in options.data) {
            let dataGet = options.data[index] === null ? '' : options.data[index];
            dataGet = dataGet === undefined ? '' : dataGet;

            if (Array.isArray(dataGet)) {
                for (let index1 in dataGet) {
                    route += index + "[]=" + dataGet[index1] + "&";
                }
            } else {
                if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
            }
        }
        route = route.slice(0, -1);

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        axios.get(route, {withCredentials: true, responseType: 'blob'})
            .then(response => new File([response.data], options.filename))
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    console.log(err.response.data);
                }
            });
    };
    this.clearCache = function (index) {
        delete cache[routeApi[index]];
    };
}

const api = new ApiHandler();

export default api;

